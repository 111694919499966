































import {Component, Prop, Vue} from "vue-property-decorator";

@Component({})
export default class AmountCards extends Vue {

  @Prop({ default: 0 })
  public amount!: any;

  @Prop({ default: 'Titel' })
  public title!: string;
}

